.status {
  background-color: #63bdf9;
  color: #fff;
  border-radius: 0 0 20px 20px;
}

.status--margin {
  margin-bottom: 45px;
}

.status__title {
  font-size: 1.2em;
  padding-top: 10px;
  margin-bottom: 5px;
}

.status__description {
  font-size: 2.6em;
  line-height: 1;
  margin-bottom: 10px;
}

.status__btn {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: inherit;
  height: 48px;
  color: #fff;
  font-size: 1.2em;
  padding: 12px 40px;
  outline: none;
  cursor: pointer;
}

.status__btn--empty {
  height: 48px;
}

.status--critical {
  background-color: rgb(246, 62, 54);
}

.phone__btn {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 8px;
}

@media (min-width: 700px) {
  .status {
    border-radius: 0 0 40px 40px;
  }
  .status--margin {
    margin-bottom: 25px;
  }
}

@media (min-width: 900px) {
  .status {
    border-radius: 0 0 20px 20px;
  }
}

@media (min-width: 1440px) {
  .status__title {
    padding-top: 20px;
  }
}

@media (min-width: 1900px) {
  .status {
    border-radius: 0 0 30px 30px;
  }
  .status--margin {
    margin-bottom: 30px;
  }

  .status__description {
    margin-bottom: 20px;
  }

  .status__btn {
    height: 60px;
  }
  .status__btn--empty {
    height: 60px;
  }

  .phone__btn {
    padding-bottom: 20px;
  }
}

@media (min-width: 3000px) {
  .status {
    border-radius: 0 0 70px 70px;
  }
  .status__title {
    font-size: 48px;
    padding-top: 60px;
  }
  .status--margin {
    margin-bottom: 80px;
  }

  .status__description {
    font-size: 100px;
    margin-bottom: 40px;
  }

  .status__btn {
    font-size: 48px;
    height: 120px;
  }
  .status__btn--empty {
    height: 120px;
  }

  .phone__btn {
    padding-bottom: 30px;
  }
}
