* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.bg {
  background: linear-gradient(#ff9045 25%, #ff7631 80%, #ff722e 100%);
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.wrapper {
  position: relative;
}

.wrapper__main {
  margin-top: 5%;
  position: relative;
  height: 70vh;
}

.wrapper__mobile {
  position: relative;
  width: 74%;
  margin: 0 auto;
}

.mobile--footer {
  position: absolute;
  width: 84.5%;
  bottom: 3.8%;
  left: 50%;
  transform: translateX(-50%);
}

.mobile--inner {
  position: relative;
  background: no-repeat;
  background-image: url(./assets/phone.svg);
  background-size: contain;
  z-index: 15;
  height: 0%;
  width: 100%;
  padding-top: 196.89%; /* image width / image height * 100%(height) = 2032 / 1032 * 100 */
}

.mobile--idle {
  background-color: rgba(98, 189, 249, 0.8);
  position: absolute;
  color: #fff;
  top: 3.5%;
  height: 93%;
  border-radius: 25px;
  width: 84.5%;
  left: 50%;
  transform: translateX(-50%);
}

.mobile--idle h1 {
  margin-top: 50%;
  font-size: 2.8em;
  margin-bottom: 20%;
}

.mobile--idle p {
  font-size: 2em;
  width: 70%;
  margin: 0 auto;
}

.mobile--idle.none {
  display: none;
}

.wrapper__counter {
  display: flex;
  justify-content: center;
}

@media (min-width: 900px) {
  .bg {
    height: 100vh;
  }

  .wrapper__main {
    margin-top: 0;
    height: 100vh;
    width: 60%;
  }

  .wrapper__mobile {
    position: absolute;
    height: 100vh;
    width: 35%;
    right: 4%;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .wrapper__main {
    width: 70%;
  }
  .wrapper__mobile {
    width: 25%;
  }
}

@media (min-width: 1440px) {
  .mobile--footer {
    width: 84%;
  }
}

@media (min-width: 1900px) {
  .mobile--idle {
    border-radius: 34px;
  }
  
  .mobile--idle h1 {
    margin-top: 50%;
    font-size: 4em;
    margin-bottom: 20%;
  }

  .mobile--idle p {
    font-size: 2.8em;
    width: 70%;
  }
}
