/* Bladeforces  */
.bladeforces {
  position: absolute;
  left: 50%;
  top: 16%; /*135px;*/
  height: 100%;
  width: 100%;
  transform: translateX(-50%);
}

.bladeforces__title {
  font-size: 1.2em;
  color: #999999;
  margin-bottom: 10px;
}

.bladeforces__flex {
  height: 24%; /*250px;*/
  display: flex;
  justify-content: space-between;
  width: 80%;
  bottom: 0;
  margin: 0 auto;
}

.bladeforces__turbine {
  background-repeat: no-repeat;
  background-image: url(../../../assets/smturbine.svg);
  background-size: contain;
  background-position: center;
  width: 35%;
}

.bladeforces__radar {
  background-repeat: no-repeat;
  background-image: url(../../../assets/bgradar.svg);
  background-size: contain;
  background-position: center;
  width: 60%;
  position: relative;
  margin-bottom: 10px;
}

.bladeforces__radar--width svg {
  position: absolute;
  width: 100%;
  height: 200%;
  left: 0px;
  bottom: -50%;
}
.bladeforces__radar--width .radarCircle {
  fill: rgba(94, 188, 252, 0.4);
}

.bladeforces__radar--width .radarCircle.failed {
  fill: rgba(249, 60, 45, 0.4);
}

/* Line */

.line {
  background-color: rgb(242, 242, 242);
  border-top: 1px solid rgb(235, 235, 235);
}

.line__title {
  color: rgb(157, 157, 157);
  font-size: 1.2em;
  padding: 10px 0 0px 0;
}

.line__chart {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 95px;
}

.line__chart svg {
  position: absolute;
  width: 120%;
  height: 100%;
  left: 0;
}

.line--stroke {
  stroke: rgb(94, 188, 252);
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.line--stroke.failed {
  stroke: rgb(249, 60, 45);
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
    transform: translate3d(0, 0, 0);
  }
  to {
    stroke-dashoffset: 0;

    transform: translate3d(-1000px, 0, 0);
  }
}

@media (min-width: 1400px) {
  .bladeforces {
    top: 17%;
  }

  .bladeforces__radar {
    margin-bottom: 20px;
  }
  .bladeforces__flex {
    height: 26%;
  }

  .line__title {
    padding: 20px 0 10px 0;
  }
}

@media (min-width: 1900px) {
  .bladeforces__radar {
    width: 55%;
  }

  .bladeforces__radar--width {
    width: 100%;
    margin: 0 auto;
  }

  .line__chart {
    height: 180px;
  }
}

@media (min-width: 3700px) {
  .line__title {
    padding: 60px 0 20px 0;
    font-size: 48px;
  }
  .bladeforces {
    top: 18%;
  }

  .bladeforces__title {
    font-size: 48px;
  }

  .bladeforces__radar--width {
    top: 1%;
    width: 78%;
  }

  .line__chart {
    height: 260px;
  }
}
