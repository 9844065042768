.rangeslider {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 1443.2px;
  width: 80%;
  padding: 60px 40px 40px 40px;

  background-color: rgba(255, 255, 255, 0.92);

  padding: 10px 10px;
  border-radius: 40px;
  box-shadow: 0 1px 20px rgba(133, 36, 31, 0.5);
}

.rangeslider__title {
  color: #999999;
  padding-top: 10px;
  font-size: 1.2em;
}

.rangeslider__range {
  -webkit-appearance: none;
  margin: 20px 0 10px 0;
  width: calc(100% - (73px));
  height: 16px;
  border-radius: 10px;
  background: #ff9045;
  outline: none;
  transition: 0.8s ease-in-out;
}

.rangeslider__range--critical {
  background: rgb(249, 60, 45);
  transition: 0.8s ease-in-out;
}

.rangeslider--start {
  position: relative;
  background: #5ebcfc;
  cursor: pointer;
  padding: 30px;
  width: 100%;
  margin: 0 auto;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1;
  outline: none;
}

.rangeslider__range::-webkit-slider-thumb {
  z-index: 100;
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.rangeslider__range::-moz-range-thumb {
  z-index: 100;
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}

.rangeslider__range::-moz-focus-outer {
  border: 0;
}

.rangeslider__range:focus::-moz-range-thumb {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4),
    0px 0px 1px 15px rgba(255, 144, 69, 0.3);
  transition: 0.5s ease-in-out;
}

.rangeslider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4),
    0px 0px 1px 15px rgba(255, 144, 69, 0.3);
  transition: 0.5s ease-in-out;
}

.rangeslider__range--critical:focus::-moz-range-thumb {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4),
    0px 0px 1px 15px rgba(249, 60, 45, 0.3);
  transition: 0.5s ease-in-out;
}

.rangeslider__range--critical:focus::-webkit-slider-thumb {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4),
    0px 0px 1px 15px rgba(249, 60, 45, 0.3);
  transition: 0.5s ease-in-out;
}

input:nth-child(3)::after {
  width: 5px;
  height: 5px;
  background-color: red;
}

@media (min-width: 1200px) {
  .rangeslider {
    width: 54%;
  }
}

@media (min-width: 3000px) {
  .rangeslider {
    margin: 0 auto;
    padding: 40px 40px 40px 40px;
    border-radius: 100px;
    box-shadow: 0 1px 60px rgba(133, 36, 31, 0.6);
  }
  .rangeslider__title {
    padding-top: 50px;
    font-size: 48px;
  }

  .rangeslider__range {
    width: calc(100% - (73px));
    height: 26px;
    border-radius: 10px;
  }

  .rangeslider--start {
    font-size: 70px;
    padding: 50px;
    border-radius: 60px;
  }

  .rangeslider__range::-webkit-slider-thumb {
    width: 80px;
    height: 80px;
  }

  .rangeslider__range::-moz-range-thumb {
    width: 80px;
    height: 80px;
  }

  .rangeslider__range:focus::-moz-range-thumb {
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.4),
      0px 0px 1px 30px rgba(255, 144, 69, 0.3);
    transition: 0.5s ease-in-out;
  }

  .rangeslider__range:focus::-webkit-slider-thumb {
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.4),
      0px 0px 1px 30px rgba(255, 144, 69, 0.3);
    transition: 0.5s ease-in-out;
  }

  .rangeslider__range--critical:focus::-moz-range-thumb {
    box-shadow: 0 1px 35px rgba(0, 0, 0, 0.4),
      0px 0px 1px 30px rgba(249, 60, 45, 0.3);
    transition: 0.5s ease-in-out;
  }

  .rangeslider__range--critical:focus::-webkit-slider-thumb {
    box-shadow: 0 1px 35px rgba(0, 0, 0, 0.4),
      0px 0px 1px 30px rgba(249, 60, 45, 0.3);
    transition: 0.5s ease-in-out;
  }
}
