/* Clouds */

.wrapper__cloud {
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.cloud {
  position: absolute;
}

.cloud svg {
  width: 100%;
}

.cloud1 {
  width: 320px;
  height: 145px;
  top: 30%;
  animation: clouds-in-out 60s -14s infinite linear both;
}

.cloud2 {
  width: 500px;
  height: 200px;
  top: 0%;
  animation: clouds-in-out 60s -30s infinite linear both;
}

.cloud3 {
  width: 400px;
  height: 180px;
  bottom: 0%;
  animation: clouds-in-out 60s -22s infinite linear both;
}

.cloud4 {
  width: 400px;
  height: 180px;
  top: 65%;
  animation: clouds-in-out 60s -4s infinite linear both;
}

.cloud5 {
  width: 450px;
  height: 200px;
  top: 30%;
  animation: clouds-in-out 60s 8s infinite linear both;
}

@keyframes clouds-in-out {
  from {
    transform: translate3d(-1200px, 0, 0);
  }
  to {
    transform: translate3d(100vw, 0, 0);
  }
}

/* Logo */

.logo {
  position: absolute;
  top: 0%;
  right: 5%;
  width: 28%;
  z-index: 10;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .logo {
    top: 81%;
    left: 3%;
    width: 16%;
  }
}

@media (min-width: 3000px) {
  .logo {
    top: 81%;
    left: 3%;
    width: 17%;
  }
}
/* Bird */

.wrapper__bird svg {
  position: fixed;
  overflow: hidden;
  top: 25%;
  width: 100px;
  height: 100px;
  animation: flying-bird 15s 0s infinite ease-in both;
}

@keyframes flying-bird {
  from {
    transform: translate3d(100vw, 0, 0);
  }
  70% {
    transform: translate3d(100vw, 0, 0);
  }
  to {
    transform: translate3d(-2500px, 0, 0);
  }
}

/* Ufo */

.wrapper__ufo svg {
  position: fixed;
  overflow: hidden;
  width: 130px;
  height: 130px;
  animation: flying-ufo 20s 0s infinite linear both;
}

@keyframes flying-ufo {
  0% {
    transform: translate3d(180vw, -200000px, 0);
  }
  40% {
    transform: translate3d(180vw, -20000px, 0);
  }
  94% {
    transform: translate3d(0, 200%, 0);
  }
  97% {
    transform: translate3d(0, 200%, 0);
    opacity: 1;
  }
  98% {
    transform: translate3d(0, 200%, 0);
    opacity: 0;
  }
  99% {
    transform: translate3d(0, 200%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 200%, 0);
    opacity: 0;
  }
}

@media (min-width: 3000px) {
  .cloud1 {
    width: 820px;
    height: 370px;
  }

  .cloud2 {
    width: 1200px;
    height: 555px;
  }

  .cloud3 {
    width: 800px;
    height: 360px;
  }

  .cloud4 {
    width: 1000px;
    height: 455px;
  }

  .wrapper__bird svg {
    width: 300px;
    height: 300px;
  }

  .wrapper__ufo svg {
    width: 330px;
    height: 330px;
  }
}
