.generatedvalue {
  color: #fff;
  text-align: left;
  margin-top: 5%;
  padding-left: 5%;
  z-index: 5;
}
.generatedvalue-header {
  font-size: 1.2em;
  font-weight: bold;
}

.generatedvalue-amount {
  font-size: 2em;
  font-weight: bold;
}

.countdown span {
  display: inline-block;
}

.countdown {
  position: relative;
  margin: 0 auto;
  width: 50%;
  min-height: 80px;
  margin-top: 10%;
  padding: 0px 30px;
  border-radius: 40px;
  z-index: 5;

  display: flex;
  flex-flow: column;
  justify-content: center;

  font-size: 1.2em;
  font-weight: bold;
  color: #fff;

  transition: 0.2s ease-in-out;
}

.countdown--broken {
  background-color: rgba(246, 62, 54, 0.94);
  /* box-shadow: 0 1px 20px rgba(133, 36, 31, 0.5); */
  transition: 0.4s ease-in-out;
}

.countdown--win {
  background-color: rgba(99, 189, 249, 0.94);
  /* box-shadow: 0 1px 20px rgba(133, 36, 31, 0.5); */
  transition: 0.4s ease-in-out;
}
@media (min-width: 700px) {
  .countdown {
    margin-top: 10%;
  }
}

@media (min-width: 1200px) {
  .generatedvalue {
    position: absolute;
    margin-top: 0;
  }

  .generatedvalue-header {
    font-size: 1.4em;
  }

  .generatedvalue-amount {
    font-size: 2.2em;
  }

  .countdown {
    font-size: 1.4em;
    margin-top: 5%;
    width: 35%;
  }
}

@media (min-width: 1440px) {
  .generatedvalue-header {
    font-size: 1.6em;
  }

  .generatedvalue-amount {
    font-size: 2.6em;
  }
  .countdown {
    font-size: 1.6em;
    min-height: 110px;
  }
}

@media (min-width: 1900px) {
  .generatedvalue-header {
    font-size: 2em;
  }

  .generatedvalue-amount {
    font-size: 2.8em;
  }
  .countdown {
    font-size: 2em;
    width: 35%;
    padding: 5px 60px;
  }
}

@media (min-width: 3000px) {
  .generatedvalue-header {
    font-size: 70px;
  }
  .generatedvalue-amount {
    font-size: 100px;
  }
  .countdown {
    font-size: 70px;
    padding: 60px 80px;
    border-radius: 100px;
  }

  .countdown--broken,
  .countdown--win {
    /* box-shadow: 0 1px 20px rgba(133, 36, 31, 0.5); */
  }
}
