.turbine svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  transform: translateX(-50%) scale(3);
  left: 50%;
  transform-origin: bottom center;
  z-index: 0;
}

.turbine .fading {
  fill: #5ebcfc;
  transition: fill 200ms linear;
}

.turbine .fading.failing {
  fill: #f93c2d;
}

.turbine .failedWing.failed {
  animation: 2s linear 1 forwards blinkOut;
}

@keyframes blinkOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (min-width: 3000px) {
  .turbine svg {
    top: 170%;
    transform: translateX(-50%) scale(4.5);
  }
}
